/** @format */

import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
function DetalleRecetas({ id }) {
  const [receta, setReceta] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/receta/${id}`)
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setReceta(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {loading && <div>Espera un momento, estamos obteniendo la informacion de la receta...</div>}
      {error && (
        <div>
          <br />
          <br />
          <br />
          <br />
          Ups!! Ocurrio un error al obtener la receta, intenta recargar la página nuevamente.
          <br /> Si el problema persiste contacta al administrador del sitio.
        </div>
      )}
      {receta && (
        <>
          <Link to="/recetas/"> &nbsp;&lt;&nbsp; Regresar </Link>
          <br />
          <br />
          <h1>{receta.nombreReceta}</h1>
          <div className="detalle-receta-container">
            <div className="detalle-receta-texto" dangerouslySetInnerHTML={{ __html: receta.receta }} />
            <div className="detalle-receta-imagen">
              <img src={`${process.env.API_URL_CHAPIN}` + receta.url} alt={receta.nombreReceta} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DetalleRecetas;
